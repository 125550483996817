define("discourse/plugins/discourse-events/discourse/controllers/admin-events-source", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "I18n", "discourse/plugins/discourse-events/discourse/mixins/message", "discourse/plugins/discourse-events/discourse/models/source", "discourse/plugins/discourse-events/discourse/models/source-options"], function (_exports, _controller, _computed, _service, _I18n, _message, _source, _sourceOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, {
    hasSources: (0, _computed.notEmpty)("sources"),
    viewName: "source",
    dialog: (0, _service.service)(),
    actions: {
      addSource() {
        this.get("sources").pushObject(_source.default.create({
          id: "new",
          source_options: _sourceOptions.default.create(),
          from_time: moment().subtract(1, "months").add(30, "minutes").startOf("hour"),
          to_time: moment().add(5, "months").add(30, "minutes").startOf("hour")
        }));
      },
      removeSource(source) {
        if (source.id === "new") {
          this.get("sources").removeObject(source);
        } else {
          this.dialog.confirm({
            message: _I18n.default.t("admin.events.source.remove.confirm", {
              source_name: source.name
            }),
            confirmButtonLabel: "admin.events.source.remove.label",
            cancelButtonLabel: "cancel",
            didConfirm: () => {
              _source.default.destroy(source).then(() => {
                this.get("sources").removeObject(source);
              });
            }
          });
        }
      }
    }
  });
});