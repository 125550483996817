define("discourse/plugins/discourse-events/discourse/templates/components/events-calendar-subscription-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="events-calendar-subscription-warning">
    {{d-icon "exclamation-triangle"}}
    {{I18n "events_calendar.subscription.warning"}}
  </div>
  */
  {
    "id": "5wNlAEVJ",
    "block": "[[[10,0],[14,0,\"events-calendar-subscription-warning\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"exclamation-triangle\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"events_calendar.subscription.warning\"],null]],[1,\"\\n\"],[13]],[],false,[\"d-icon\",\"I18n\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/components/events-calendar-subscription-warning.hbs",
    "isStrictMode": false
  });
});