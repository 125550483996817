define("discourse/plugins/discourse-events/discourse/templates/admin-events", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="events-beta-notice">
    {{emoji "wave"}}{{html-safe (i18n "admin.events.beta_notice")}}
  </div>
  
  <AdminNav>
    {{nav-item
      route="admin.events.connection"
      label="admin.events.connection.title"
    }}
    {{nav-item route="admin.events.source" label="admin.events.source.title"}}
    {{nav-item route="admin.events.provider" label="admin.events.provider.title"}}
    {{nav-item route="admin.events.event" label="admin.events.event.title"}}
    {{nav-item route="admin.events.log" label="admin.events.log.title"}}
  
    <li class="settings">
      <DButton
        @class="btn-default"
        @action={{route-action "showSettings"}}
        @actionParam={{plugin}}
        @icon="cog"
        @label="admin.plugins.change_settings_short"
      />
    </li>
  </AdminNav>
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "Mh0dJYQA",
    "block": "[[[10,0],[14,0,\"events-beta-notice\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"wave\"],null]],[1,[28,[35,1],[[28,[37,2],[\"admin.events.beta_notice\"],null]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,4],null,[[\"route\",\"label\"],[\"admin.events.connection\",\"admin.events.connection.title\"]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"route\",\"label\"],[\"admin.events.source\",\"admin.events.source.title\"]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"route\",\"label\"],[\"admin.events.provider\",\"admin.events.provider.title\"]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"route\",\"label\"],[\"admin.events.event\",\"admin.events.event.title\"]]]],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"route\",\"label\"],[\"admin.events.log\",\"admin.events.log.title\"]]]],[1,\"\\n\\n  \"],[10,\"li\"],[14,0,\"settings\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@class\",\"@action\",\"@actionParam\",\"@icon\",\"@label\"],[\"btn-default\",[28,[37,6],[\"showSettings\"],null],[30,0,[\"plugin\"]],\"cog\",\"admin.plugins.change_settings_short\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `plugin` property path was used in the `discourse/plugins/discourse-events/discourse/templates/admin-events.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.plugin}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"emoji\",\"html-safe\",\"i18n\",\"admin-nav\",\"nav-item\",\"d-button\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/admin-events.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});