define("discourse/plugins/discourse-events/discourse/models/provider", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Provider = _object.default.extend();
  Provider.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/events/provider").catch(_ajaxError.popupAjaxError);
    },
    update(provider) {
      return (0, _ajax.ajax)(`/admin/events/provider/${provider.id}`, {
        type: "PUT",
        data: {
          provider
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(provider) {
      return (0, _ajax.ajax)(`/admin/events/provider/${provider.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Provider;
});