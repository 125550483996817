define("discourse/plugins/discourse-events/discourse/components/events-event-row", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)("showSelect"), _dec2 = (0, _decorators.observes)("selectAll"), (_obj = {
    tagName: "tr",
    classNameBindings: [":events-event-row", "showSelect", "selected"],
    selected: false,
    toggleWhenShowSelect() {
      if (!this.showSelect) {
        this.set("selected", false);
      }
    },
    toggleWhenSelectAll() {
      this.set("selected", this.selectAll);
    },
    click() {
      if (this.showSelect) {
        this.selectEvent();
      }
    },
    selectEvent() {
      this.toggleProperty("selected");
      this.modifySelection([this.event], this.selected);
    },
    actions: {
      selectEvent() {
        this.selectEvent();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "toggleWhenShowSelect", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleWhenShowSelect"), _obj), _applyDecoratedDescriptor(_obj, "toggleWhenSelectAll", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleWhenSelectAll"), _obj)), _obj)));
});