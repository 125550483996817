define("discourse/plugins/discourse-events/discourse/components/events-event-table", ["exports", "@ember/component", "discourse/mixins/load-more", "discourse/plugins/discourse-events/discourse/models/event"], function (_exports, _component, _loadMore, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_loadMore.default, {
    classNames: ["events-event-table"],
    eyelineSelector: ".events-event-row",
    loadingComplete: false,
    actions: {
      toggleSelectAll() {
        this.toggleProperty("selectAll");
        this.modifySelection(this.events, this.selectAll);
      },
      loadMore() {
        if (this.loading || this.loadingComplete) {
          return;
        }
        let page = this.page + 1;
        this.set("page", page);
        this.set("loading", true);
        _event.default.list({
          page
        }).then(result => {
          if (result.events && result.events.length) {
            this.get("events").pushObjects(_event.default.eventsArray(result.events));
          } else {
            this.set("loadingComplete", true);
          }
        }).finally(() => this.set("loading", false));
      }
    }
  });
});