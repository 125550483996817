define("discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-interface/calendar-preferences", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group calendar">
    <label class="control-label">{{i18n "user.events_calendar.label"}}</label>
    <div class="controls">
      <label>{{i18n "user.events_calendar.first_day_week"}}</label>
      {{combo-box
        content=weekdaysAvailable
        value=controller.model.custom_fields.calendar_first_day_week
      }}
    </div>
  </div>
  */
  {
    "id": "1Woz7FA0",
    "block": "[[[10,0],[14,0,\"control-group calendar\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.events_calendar.label\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"user.events_calendar.first_day_week\"],null]],[13],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"content\",\"value\"],[[30,0,[\"weekdaysAvailable\"]],[30,0,[\"controller\",\"model\",\"custom_fields\",\"calendar_first_day_week\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `weekdaysAvailable` property path was used in the `discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-interface/calendar-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.weekdaysAvailable}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `controller` property path was used in the `discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-interface/calendar-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.controller}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"combo-box\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/templates/connectors/user-preferences-interface/calendar-preferences.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});