define("discourse/plugins/discourse-events/discourse/components/add-event-controls", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/date-utilities", "discourse/plugins/discourse-events/discourse/components/modal/add-event"], function (_exports, _component, _service, _decorators, _dateUtilities, _addEvent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("noText"), _dec2 = (0, _decorators.default)("event"), _dec3 = (0, _decorators.default)("category", "noText"), (_obj = {
    classNames: ["event-label"],
    modal: (0, _service.service)(),
    didInsertElement() {
      this._super(...arguments);
      $(".title-and-category").toggleClass("event-add-no-text", this.get("iconOnly"));
    },
    valueClasses(noText) {
      let classes = "add-event";
      if (noText) {
        classes += " btn-primary";
      }
      return classes;
    },
    valueLabel(event) {
      return (0, _dateUtilities.eventLabel)(event, {
        noText: this.get("noText"),
        useEventTimezone: true,
        showRsvp: true,
        siteSettings: this.siteSettings
      });
    },
    iconOnly(category, noText) {
      return noText || this.siteSettings.events_event_label_no_text || Boolean(category && category.get("custom_fields.events_event_label_no_text"));
    },
    actions: {
      showAddEvent() {
        this.modal.show(_addEvent.default, {
          model: {
            bufferedEvent: this.event,
            event: this.event,
            update: event => {
              this.set("event", event);
            }
          }
        });
      },
      removeEvent() {
        this.set("event", null);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "valueClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "valueClasses"), _obj), _applyDecoratedDescriptor(_obj, "valueLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "valueLabel"), _obj), _applyDecoratedDescriptor(_obj, "iconOnly", [_dec3], Object.getOwnPropertyDescriptor(_obj, "iconOnly"), _obj)), _obj)));
});