define("discourse/plugins/discourse-events/discourse/connectors/composer-fields/composer-controls-event", ["exports", "discourse-common/lib/get-owner"], function (_exports, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      const controller = (0, _getOwner.getOwner)(this).lookup("controller:composer");
      component.set("eventValidation", controller.get("eventValidation"));
      controller.addObserver("eventValidation", () => {
        if (this._state === "destroying") {
          return;
        }
        component.set("eventValidation", controller.get("eventValidation"));
      });
    }
  };
});