define("discourse/plugins/discourse-events/discourse/components/events-date-picker", ["exports", "@ember/runloop", "discourse/components/date-picker", "discourse/lib/load-script", "discourse-common/lib/object", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _runloop, _datePicker, _loadScript, _object, _decorators, _I18n, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _datePicker.default.extend((_dec = (0, _decorators.observes)("value"), _dec2 = (0, _decorators.on)("didInsertElement"), (_obj = {
    layoutName: "components/date-picker",
    setDate() {
      if (this._picker && this.value) {
        this._picker.setDate(this.value);
      }
    },
    _loadDatePicker() {
      const input = this.element.querySelector(".date-picker");
      const container = document.getElementById(this.get("containerId"));
      (0, _loadScript.default)("/javascripts/pikaday.js").then(() => {
        (0, _runloop.next)(() => {
          let default_opts = {
            field: input,
            container: container || this.element,
            bound: container === undefined,
            format: "YYYY-MM-DD",
            firstDay: (0, _dateUtilities.firstDayOfWeek)(),
            i18n: {
              previousMonth: _I18n.default.t("dates.previous_month"),
              nextMonth: _I18n.default.t("dates.next_month"),
              months: moment.months(),
              weekdays: moment.weekdays(),
              weekdaysShort: moment.weekdaysShort()
            },
            onSelect: date => {
              const formattedDate = moment(date).format("YYYY-MM-DD");
              if (this.attrs.onSelect) {
                this.attrs.onSelect(formattedDate);
              }
              if (!this.element || this.isDestroying || this.isDestroyed) {
                return;
              }
              this.set("value", formattedDate);
            }
          };
          this._picker = new Pikaday((0, _object.deepMerge)(default_opts, this._opts())); // eslint-disable-line no-undef
        });
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "setDate", [_dec], Object.getOwnPropertyDescriptor(_obj, "setDate"), _obj), _applyDecoratedDescriptor(_obj, "_loadDatePicker", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_loadDatePicker"), _obj)), _obj)));
});