define("discourse/plugins/discourse-events/discourse/components/events-calendar", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/models/category", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _computed, _runloop, _service, _category, _decorators, _I18n, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const RESPONSIVE_BREAKPOINT = 800;
  const YEARS = [moment().subtract(1, "year").year(), moment().year(), moment().add(1, "year").year()];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("siteSettings.login_required", "category.read_restricted"), _dec3 = (0, _decorators.on)("willDestroy"), _dec4 = (0, _decorators.default)("responsive"), _dec5 = (0, _decorators.default)("currentDate", "currentMonth", "currentYear", "topics.[]"), _dec6 = (0, _decorators.default)("currentMonth", "currentYear"), _dec7 = (0, _decorators.default)("category"), _dec8 = (0, _decorators.observes)("month", "year"), (_obj = {
    classNameBindings: [":events-calendar", "responsive"],
    showEvents: (0, _computed.not)("eventsBelow"),
    canSelectDate: (0, _computed.alias)("eventsBelow"),
    routing: (0, _service.inject)("-routing"),
    queryParams: (0, _computed.alias)("routing.router.currentState.routerJsState.fullQueryParams"),
    years: YEARS.map(y => ({
      id: y,
      name: y
    })),
    layoutName: "components/events-calendar",
    webcalDocumentationURL: "https://coop.pavilion.tech/t/1447",
    setup() {
      this._super();
      moment.locale(_I18n.default.locale);
      (0, _runloop.scheduleOnce)("afterRender", () => {
        this.handleResize();
        $(window).on("resize", (0, _runloop.bind)(this, this.handleResize));
        $("body").addClass("calendar");
      });
      let currentDate = moment().date();
      let currentMonth = moment().month();
      let currentYear = moment().year();

      // get month and year from the date in middle of the event range
      const initialDateRange = this.get("initialDateRange");
      const queryParams = this.get("queryParams");
      let dateRange = {};
      if (initialDateRange) {
        dateRange = initialDateRange;
      }
      if (queryParams.start) {
        dateRange.start = queryParams.start;
      }
      if (queryParams.end) {
        dateRange.end = queryParams.end;
      }
      if (dateRange.start && dateRange.end) {
        const start = moment(dateRange.start);
        const end = moment(dateRange.end);
        const diff = Math.abs(start.diff(end, "days"));
        const middleDay = start.add(diff / 2, "days");
        currentMonth = middleDay.month();
        currentYear = middleDay.year();
      }
      let month = currentMonth;
      let year = currentYear;
      this.setProperties({
        currentDate,
        currentMonth,
        currentYear,
        month,
        year
      });
    },
    showNotice(loginRequired, categoryRestricted) {
      return loginRequired || categoryRestricted;
    },
    teardown() {
      $(window).off("resize", (0, _runloop.bind)(this, this.handleResize));
      $("body").removeClass("calendar");
    },
    handleResize() {
      if (this._state === "destroying") {
        return;
      }
      this.set("responsiveBreak", $(window).width() < RESPONSIVE_BREAKPOINT);
    },
    forceResponsive: false,
    responsive: (0, _computed.or)("forceResponsive", "responsiveBreak", "site.mobileView"),
    showFullTitle: (0, _computed.not)("responsive"),
    eventsBelow: (0, _computed.alias)("responsive"),
    todayLabel(responsive) {
      return responsive ? null : "events_calendar.today";
    },
    months() {
      return moment.localeData().months().map((m, i) => {
        return {
          id: i,
          name: m
        };
      });
    },
    dateEvents(currentDate, currentMonth, currentYear, topics) {
      const day = moment().year(currentYear).month(currentMonth);
      return (0, _dateUtilities.eventsForDay)(day.date(currentDate), topics, {
        dateEvents: true,
        siteSettings: this.siteSettings
      });
    },
    days(currentMonth, currentYear) {
      const {
        start,
        end
      } = (0, _dateUtilities.calendarDays)(currentMonth, currentYear);
      let days = [];
      for (let day = moment(start); day.isBefore(end); day.add(1, "days")) {
        days.push(moment().year(day.year()).month(day.month()).date(day.date()));
      }
      return days;
    },
    showSubscription() {
      return true; // !category || !category.read_restricted;
    },
    transitionToMonth(month, year) {
      const {
        start,
        end
      } = (0, _dateUtilities.calendarRange)(month, year);
      const router = this.get("routing.router");
      if (this.get("loading")) {
        return;
      }
      this.set("loading", true);
      return router.transitionTo({
        queryParams: {
          start,
          end
        }
      }).then(() => {
        const category = this.get("category");
        let filter = "";
        if (category) {
          filter += `c/${_category.default.slugFor(category)}/l/`;
        }
        filter += "calendar";
        this.store.findFiltered("topicList", {
          filter,
          params: {
            start,
            end
          }
        }).then(list => {
          if (this._state === "destroying") {
            return;
          }
          this.setProperties({
            topics: list.topics,
            currentMonth: month,
            currentYear: year,
            loading: false
          });
        });
      });
    },
    getNewTopics() {
      const currentMonth = this.get("currentMonth");
      const currentYear = this.get("currentYear");
      const month = this.get("month");
      const year = this.get("year");
      if (currentMonth !== month || currentYear !== year) {
        this.transitionToMonth(month, year);
      }
    },
    actions: {
      selectDate(selectedDate, selectedMonth) {
        const month = this.get("month");
        if (month !== selectedMonth) {
          this.set("month", selectedMonth);
        }
        this.set("currentDate", selectedDate);
      },
      today() {
        this.setProperties({
          month: moment().month(),
          year: moment().year(),
          currentDate: moment().date()
        });
      },
      monthPrevious() {
        let currentMonth = this.get("currentMonth");
        let year = this.get("currentYear");
        let month;
        if (currentMonth === 0) {
          month = 11;
          year = year - 1;
        } else {
          month = currentMonth - 1;
        }
        this.setProperties({
          month,
          year
        });
      },
      monthNext() {
        let currentMonth = this.get("currentMonth");
        let year = this.get("currentYear");
        let month;
        if (currentMonth === 11) {
          month = 0;
          year = year + 1;
        } else {
          month = currentMonth + 1;
        }
        this.setProperties({
          month,
          year
        });
      },
      changeSubscription() {}
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "showNotice", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showNotice"), _obj), _applyDecoratedDescriptor(_obj, "teardown", [_dec3], Object.getOwnPropertyDescriptor(_obj, "teardown"), _obj), _applyDecoratedDescriptor(_obj, "todayLabel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "todayLabel"), _obj), _applyDecoratedDescriptor(_obj, "months", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "months"), _obj), _applyDecoratedDescriptor(_obj, "dateEvents", [_dec5], Object.getOwnPropertyDescriptor(_obj, "dateEvents"), _obj), _applyDecoratedDescriptor(_obj, "days", [_dec6], Object.getOwnPropertyDescriptor(_obj, "days"), _obj), _applyDecoratedDescriptor(_obj, "showSubscription", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showSubscription"), _obj), _applyDecoratedDescriptor(_obj, "getNewTopics", [_dec8], Object.getOwnPropertyDescriptor(_obj, "getNewTopics"), _obj)), _obj)));
});