define("discourse/plugins/discourse-events/discourse/components/events-calendar-day", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "@ember/template", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _computed, _runloop, _template, _decorators, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const MAX_EVENTS = 4;
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("date", "month", "expandedDate"), _dec2 = (0, _decorators.default)("month", "currentMonth"), _dec3 = (0, _decorators.on)("init"), _dec4 = (0, _decorators.observes)("expanded"), _dec5 = (0, _decorators.default)("day", "topics.[]", "expanded", "rowIndex"), _dec6 = (0, _decorators.default)("index"), _dec7 = (0, _decorators.default)("index"), _dec8 = (0, _decorators.default)("index"), _dec9 = (0, _decorators.default)("day", "currentDate", "currentMonth", "expanded", "responsive"), _dec10 = (0, _decorators.default)("expanded"), (_obj = {
    classNameBindings: [":day", "classes", "differentMonth"],
    attributeBindings: ["day:data-day"],
    hidden: 0,
    hasHidden: (0, _computed.gt)("hidden", 0),
    expanded(date, month, expandedDate) {
      return `${month}.${date}` === expandedDate;
    },
    differentMonth(month, currentMonth) {
      return month !== currentMonth;
    },
    setEvents() {
      const expanded = this.get("expanded");
      const allEvents = this.get("allEvents");
      let events = $.extend([], allEvents);
      if (events.length && !expanded) {
        let hidden = events.splice(MAX_EVENTS);
        if (hidden.length) {
          this.set("hidden", hidden.length);
        }
      } else {
        this.set("hidden", 0);
      }
      this.set("events", events);
    },
    allEvents(day, topics, expanded, rowIndex) {
      return (0, _dateUtilities.eventsForDay)(day, topics, {
        rowIndex,
        expanded,
        siteSettings: this.siteSettings
      });
    },
    rowIndex(index) {
      return index % 7;
    },
    didInsertElement() {
      this._super(...arguments);
      this.set("clickHandler", (0, _runloop.bind)(this, this.documentClick));
      $(document).on("click", this.get("clickHandler"));
    },
    willDestroyElement() {
      this._super(...arguments);
      $(document).off("click", this.get("clickHandler"));
    },
    documentClick(event) {
      if (!event.target.closest(`.events-calendar-body .day[data-day='${this.day}']`)) {
        this.clickOutside();
      } else {
        this.click();
      }
    },
    clickOutside() {
      if (this.get("expanded")) {
        this.get("setExpandedDate")(null);
      }
    },
    click() {
      const canSelectDate = this.get("canSelectDate");
      if (canSelectDate) {
        const date = this.get("date");
        const month = this.get("month");
        this.selectDate(date, month);
      }
    },
    date() {
      const day = this.get("day");
      return day.date();
    },
    month() {
      const day = this.get("day");
      return day.month();
    },
    classes(day, currentDate, currentMonth, expanded, responsive) {
      let classes = "";
      if (day.isSame(moment(), "day")) {
        classes += "today ";
      }
      if (responsive && day.isSame(moment().month(currentMonth).date(currentDate), "day")) {
        classes += "selected ";
      }
      if (expanded) {
        classes += "expanded";
      }
      return classes;
    },
    containerStyle(expanded) {
      let style = "";
      if (expanded) {
        const offsetLeft = this.element.offsetLeft;
        const offsetTop = this.element.offsetTop;
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        if (offsetLeft > windowWidth / 2) {
          style += "right:0;";
        } else {
          style += "left:0;";
        }
        if (offsetTop > windowHeight / 2) {
          style += "bottom:0;";
        } else {
          style += "top:0;";
        }
      }
      return (0, _template.htmlSafe)(style);
    }
  }, (_applyDecoratedDescriptor(_obj, "expanded", [_dec], Object.getOwnPropertyDescriptor(_obj, "expanded"), _obj), _applyDecoratedDescriptor(_obj, "differentMonth", [_dec2], Object.getOwnPropertyDescriptor(_obj, "differentMonth"), _obj), _applyDecoratedDescriptor(_obj, "setEvents", [_dec3, _dec4], Object.getOwnPropertyDescriptor(_obj, "setEvents"), _obj), _applyDecoratedDescriptor(_obj, "allEvents", [_dec5], Object.getOwnPropertyDescriptor(_obj, "allEvents"), _obj), _applyDecoratedDescriptor(_obj, "rowIndex", [_dec6], Object.getOwnPropertyDescriptor(_obj, "rowIndex"), _obj), _applyDecoratedDescriptor(_obj, "date", [_dec7], Object.getOwnPropertyDescriptor(_obj, "date"), _obj), _applyDecoratedDescriptor(_obj, "month", [_dec8], Object.getOwnPropertyDescriptor(_obj, "month"), _obj), _applyDecoratedDescriptor(_obj, "classes", [_dec9], Object.getOwnPropertyDescriptor(_obj, "classes"), _obj), _applyDecoratedDescriptor(_obj, "containerStyle", [_dec10], Object.getOwnPropertyDescriptor(_obj, "containerStyle"), _obj)), _obj)));
});