define("discourse/plugins/discourse-events/discourse/components/events-calendar-subscription", ["exports", "discourse/models/category", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _category, _getUrl, _decorators, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _dropdownSelectBox.default.extend((_dec = (0, _decorators.default)(), (_obj = {
    classNames: ["events-calendar-subscription"],
    modifyComponentForRow() {
      return "events-calendar-subscription-row";
    },
    getDomain() {
      return location.hostname + (location.port ? ":" + location.port : "");
    },
    content() {
      const path = this.category ? `/c/${_category.default.slugFor(this.category)}/l` : "";
      const url = this.getDomain() + (0, _getUrl.default)(path);
      const timeZone = moment.tz.guess();
      return [{
        id: `webcal://${url}/calendar.ics?time_zone=${timeZone}`,
        name: _I18n.default.t("events_calendar.ical")
      }, {
        id: `${url}/calendar.rss?time_zone=${timeZone}`,
        name: _I18n.default.t("events_calendar.rss")
      }];
    },
    actions: {
      onSelect() {}
    }
  }, (_applyDecoratedDescriptor(_obj, "content", [_dec], Object.getOwnPropertyDescriptor(_obj, "content"), _obj)), _obj)));
});