define("discourse/plugins/discourse-events/discourse/raw-templates/connectors/topic-list-before-status/topic-list-event-label", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class=\"date-time-container\">\n    <a\n      href=" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.lastUnreadUrl", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 11
          },
          "end": {
            "line": 4,
            "column": 42
          }
        }
      })) + "\n      class=" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.topicListItemClasses", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 12
          },
          "end": {
            "line": 5,
            "column": 50
          }
        }
      })) + "\n    >\n      " + alias2((lookupProperty(helpers, "event-label") || depth0 && lookupProperty(depth0, "event-label") || container.hooks.helperMissing).call(alias1, "context.topic.event", {
        "name": "event-label",
        "hash": {
          "list": "true"
        },
        "hashTypes": {
          "list": "StringLiteral"
        },
        "hashContexts": {
          "list": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 6
          },
          "end": {
            "line": 7,
            "column": 53
          }
        }
      })) + "\n    </a>\n  </div>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.event", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 7
          }
        }
      })) != null ? stack1 : "";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-before-status/topic-list-event-label", template, {
    core: true
  });
  var _default = _exports.default = template;
});