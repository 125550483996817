define("discourse/plugins/discourse-events/discourse/routes/admin-events-source", ["exports", "@ember/array", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/provider", "discourse/plugins/discourse-events/discourse/models/source", "discourse/plugins/discourse-events/discourse/models/source-options"], function (_exports, _array, _discourse, _provider, _source, _sourceOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _source.default.all();
    },
    setupController(controller, model) {
      controller.setProperties({
        sources: (0, _array.A)(model.sources.map(s => {
          s.source_options = _sourceOptions.default.create(s.source_options);
          return _source.default.create(s);
        })),
        providers: (0, _array.A)(model.providers.map(p => _provider.default.create(p)))
      });
    }
  });
});