define("discourse/plugins/discourse-events/discourse/connectors/user-preferences-interface/calendar-preferences", ["exports", "discourse-common/lib/get-owner", "I18n"], function (_exports, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      const controller = (0, _getOwner.getOwner)(this).lookup("controller:preferences/interface");
      moment.locale(_I18n.default.locale);
      const data = moment.localeData();
      const weekdaysRaw = data.weekdays();
      let weekdaysAvailable = Object.keys(weekdaysRaw).reduce((weekdays, day) => {
        if (day === "6" || day <= "1") {
          weekdays.push({
            id: Number(day),
            name: weekdaysRaw[day]
          });
        }
        return weekdays;
      }, []);

      // move saturday to the start
      weekdaysAvailable.unshift(weekdaysAvailable.pop());
      component.setProperties({
        weekdaysAvailable,
        controller
      });
      if (!controller.get("model.custom_fields.calendar_first_day_week")) {
        const userFirst = controller.get("model.calendar_first_day_week");
        const localeFirst = data.firstDayOfWeek();
        const first = !isNaN(parseFloat(userFirst)) && isFinite(userFirst) ? userFirst : localeFirst;
        controller.set("model.custom_fields.calendar_first_day_week", first);
      }
    }
  };
});