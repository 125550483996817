define("discourse/plugins/discourse-events/discourse/components/event-webcal-keys", ["exports", "@ember/component", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _component, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEY_ENDPOINT = "/discourse-events/api-keys.json";
  var _default = _exports.default = _component.default.extend({
    actions: {
      show() {
        (0, _ajax.ajax)(KEY_ENDPOINT, {
          type: "GET"
        }).then(result => {
          this.set("apiKey", result["api_keys"][0]["key"]);
          this.set("clientID", result["api_keys"][0]["client_id"]);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });
});