define("discourse/plugins/discourse-events/discourse/routes/admin-events-log", ["exports", "@ember/array", "discourse/routes/discourse", "discourse/plugins/discourse-events/discourse/models/log"], function (_exports, _array, _discourse, _log) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    },
    model(params) {
      let page = params.page || 0;
      let order = params.order || "created_at";
      let asc = params.asc || false;
      return _log.default.list({
        page,
        order,
        asc
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        page: model.page,
        logs: (0, _array.A)(model.logs.map(p => _log.default.create(p)))
      });
    }
  });
});