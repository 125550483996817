define("discourse/plugins/discourse-events/discourse/components/events-log-table", ["exports", "@ember/component", "discourse/mixins/load-more", "discourse/plugins/discourse-events/discourse/models/log"], function (_exports, _component, _loadMore, _log) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_loadMore.default, {
    classNames: ["events-log-table"],
    eyelineSelector: ".events-log-row",
    loadingComplete: false,
    actions: {
      loadMore() {
        if (this.loading || this.loadingComplete) {
          return;
        }
        let page = this.page + 1;
        this.set("page", page);
        this.set("loading", true);
        _log.default.list({
          page
        }).then(result => {
          if (result.logs && result.logs.length) {
            this.get("logs").pushObjects(result.logs.map(p => _log.default.create(p)));
          } else {
            this.set("loadingComplete", true);
          }
        }).finally(() => this.set("loading", false));
      }
    }
  });
});