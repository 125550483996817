define("discourse/plugins/discourse-events/discourse/components/custom-wizard-field-event", ["exports", "@ember/component", "@ember/object/computed"], function (_exports, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layoutName: "javascripts/wizard/templates/components/wizard-field-event",
    eventTimezones: (0, _computed.alias)("field.event_timezones"),
    actions: {
      updateEvent(event, status) {
        this.set("field.value", event);
        this.field.setValid(status);
      }
    }
  });
});