define("discourse/plugins/discourse-events/discourse/controllers/admin-events-connection", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "I18n", "discourse/plugins/discourse-events/discourse/mixins/message", "discourse/plugins/discourse-events/discourse/models/connection"], function (_exports, _controller, _computed, _service, _I18n, _message, _connection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_message.default, {
    hasConnections: (0, _computed.notEmpty)("connections"),
    viewName: "connection",
    dialog: (0, _service.service)(),
    actions: {
      addConnection() {
        this.get("connections").pushObject(_connection.default.create({
          id: "new",
          from_time: moment().subtract(1, "months").add(30, "minutes").startOf("hour"),
          to_time: moment().add(5, "months").add(30, "minutes").startOf("hour")
        }));
      },
      removeConnection(connection) {
        if (connection.id === "new") {
          this.get("connections").removeObject(connection);
        } else {
          this.dialog.confirm({
            message: _I18n.default.t("admin.events.connection.remove.confirm"),
            confirmButtonLabel: "admin.events.connection.remove.label",
            cancelButtonLabel: "cancel",
            didConfirm: () => {
              _connection.default.destroy(connection).then(() => {
                this.get("connections").removeObject(connection);
              });
            }
          });
        }
      }
    }
  });
});