define("discourse/plugins/discourse-events/discourse/components/event-rsvp", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/components/modal/event-rsvp"], function (_exports, _component, _object, _computed, _service, _ajax, _ajaxError, _decorators, _I18n, _eventRsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("userGoing"), _dec2 = (0, _decorators.default)("currentUser", "eventFull"), _dec3 = (0, _decorators.default)("goingTotal", "topic.event.going_max"), _dec4 = (0, _decorators.default)("hasMax", "eventFull"), (_obj = {
    classNames: "event-rsvp",
    goingSaving: false,
    modal: (0, _service.service)(),
    didReceiveAttrs() {
      this._super();
      const currentUser = this.currentUser;
      const eventGoing = this.topic.event.going;
      this.setProperties({
        goingTotal: eventGoing ? eventGoing.length : 0,
        userGoing: currentUser && eventGoing && eventGoing.indexOf(currentUser.username) > -1
      });
    },
    goingClasses(userGoing) {
      return userGoing ? "btn-primary" : "";
    },
    canGo(currentUser, eventFull) {
      return currentUser && !eventFull;
    },
    hasGuests: (0, _computed.gt)("goingTotal", 0),
    hasMax: (0, _computed.notEmpty)("topic.event.going_max"),
    spotsLeft(goingTotal, goingMax) {
      return Number(goingMax) - Number(goingTotal);
    },
    eventFull: (0, _computed.equal)("spotsLeft", 0),
    goingMessage(hasMax, full) {
      if (hasMax) {
        if (full) {
          return _I18n.default.t("event_rsvp.going.max_reached");
        } else {
          const spotsLeft = this.get("spotsLeft");
          if (spotsLeft === 1) {
            return _I18n.default.t("event_rsvp.going.one_spot_left");
          } else {
            return _I18n.default.t("event_rsvp.going.x_spots_left", {
              spotsLeft
            });
          }
        }
      }
      return false;
    },
    updateTopic(userName, _action, type) {
      let existing = this.get(`topic.event.${type}`);
      let list = existing ? existing : [];
      let userGoing = _action === "add";
      if (userGoing) {
        list.push(userName);
      } else {
        list.splice(list.indexOf(userName), 1);
      }
      let props = {
        userGoing,
        goingTotal: list.length
      };
      props[`topic.event.${type}`] = list;
      this.setProperties(props);
    },
    save(user, _action, type) {
      this.set(`${type}Saving`, true);
      (0, _ajax.ajax)(`/discourse-events/rsvp/${_action}`, {
        type: "POST",
        data: {
          topic_id: this.get("topic.id"),
          type,
          usernames: [user.username]
        }
      }).then(result => {
        if (result.success) {
          this.updateTopic(user.username, _action, type);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.set(`${type}Saving`, false);
      });
    },
    openModal() {
      event?.preventDefault();
      this.modal.show(_eventRsvp.default, {
        model: {
          topic: this.get("topic")
        }
      });
    },
    actions: {
      going() {
        const currentUser = this.get("currentUser");
        const userGoing = this.get("userGoing");
        let _action = userGoing ? "remove" : "add";
        this.save(currentUser, _action, "going");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "goingClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "goingClasses"), _obj), _applyDecoratedDescriptor(_obj, "canGo", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canGo"), _obj), _applyDecoratedDescriptor(_obj, "spotsLeft", [_dec3], Object.getOwnPropertyDescriptor(_obj, "spotsLeft"), _obj), _applyDecoratedDescriptor(_obj, "goingMessage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "goingMessage"), _obj), _applyDecoratedDescriptor(_obj, "openModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "openModal"), _obj)), _obj)));
});