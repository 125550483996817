define("discourse/plugins/discourse-events/discourse/models/connection", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/site", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _ajaxError, _site, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const Connection = _object.default.extend((_dec = (0, _decorators.default)("category_id"), (_obj = {
    category(categoryId) {
      const categories = _site.default.current().categoriesList;
      return categories.find(c => c.id === categoryId);
    }
  }, (_applyDecoratedDescriptor(_obj, "category", [_dec], Object.getOwnPropertyDescriptor(_obj, "category"), _obj)), _obj)));
  Connection.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/events/connection").catch(_ajaxError.popupAjaxError);
    },
    update(connection) {
      return (0, _ajax.ajax)(`/admin/events/connection/${connection.id}`, {
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify({
          connection
        })
      }).catch(_ajaxError.popupAjaxError);
    },
    sync(connection) {
      return (0, _ajax.ajax)(`/admin/events/connection/${connection.id}`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(connection) {
      return (0, _ajax.ajax)(`/admin/events/connection/${connection.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Connection;
});